export default {
  en: {
    language: 'Language',

    // Nav
    browse: 'Browse',
    collection: 'My collection',
    back_to_browse: 'Go Back',

    // Mods
    mod: 'Mod | Mods',
    content: 'Content',
    subscribed: 'Subscribed',
    tags: 'Tags',
    summary: 'Summary',
    contributors: 'Contributors',
    description: 'Description',
    dependencies: 'Dependencies',
    of: 'of',
    reset_filters: 'Reset Filters',
    no_results_found: 'No {ugc_name} found',
    no_subscriptions: 'You have not subscribed to any {ugc_name}',
    mod_disclaimer_heading: 'Legal disclaimer',
    mod_disclaimer_text:
      'You are about to download a mod, which you agree may only be used within the Anno 1800 ecosystem as specifically allowed by Ubisoft. Ubisoft is not responsible for the content of any mod files or downloads, and cannot guarantee the safety, quality or stability of mods, nor provide support for mods. If you choose to continue, you do so at your own risk. Ubisoft reserves the right to have mods removed at any time for any reason. Ubisoft is committed to protecting your personal data. To provide you the best experience possible using mods including uploading, downloading, and reporting mods, Ubisoft processes your personal data. For more information on how Ubisoft processes your personal data and how you can exercise your rights, read our {mod_disclaimer_privacy}.',
    mod_disclaimer_privacy: 'Privacy Policy',

    // Actions
    login: 'Login',
    logout: 'Log out',
    search: 'Search',
    clear_all: 'Clear all',
    download: 'Download',
    subscribe: 'Subscribe',
    unsubscribe: 'Unsubscribe',
    resubscribe: 'Resubscribe',
    activate: 'Activate',
    deactivate: 'Deactivate',
    like: 'Like',
    dislike: 'Dislike',
    report: 'Report',
    submit: 'Submit',
    cancel: 'Cancel',

    // Filters
    all: 'All',
    available: 'available',
    filesize: 'Filesize',
    downloads: 'Downloads',
    todays_downloads: "Today's downloads",
    subscribers: 'Subscribers',
    rating: 'Rating',
    sort_by: 'Sort by',
    creator: 'Creator',

    // Login
    signup: 'Log in or sign up with Email',
    email_placeholder: 'Email address',
    code_placeholder: '5 digit code',
    have_code: 'I have a code',
    email_code: 'Email code',
    send_new_code: 'Send new code',
    check_email: 'Please check your email for your code and enter it here.',
    login_disclaimer:
      'You are logging in to mod.io which is used to support user-generated content in-game.',
    terms_disclaimer:
      'By continuing, you agree to the {terms} and {privacy} and must be 13 years of age or older.',
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    code_error: 'Unable to request code.',

    // Report
    report_heading: 'Report this mod',
    report_intro:
      "Report content violating the site's {terms} or submit a {dmca} using the form below. Make sure you include all relevant information and links.",
    dmca: 'DMCA complaint',
    reason: 'Reason for reporting',
    urls: 'Offending URLs',
    urls_placeholder: 'URLs (1 per line)',
    company_or_name: 'Company or your Name',
    email: 'Email',
    address: 'Address',
    country: 'Country',
    country_placeholder: 'Select a country',
    details: 'Details of Infringement',
    details_placeholder:
      'To help us process your report, please provide as much detail and evidence as possible, including:\n\nURLs, dates, account names involved\nWhere the original content can be found\nThe infringement that has occurred',
    dmca_1:
      "{mod_io} respects the intellectual property rights of others, and we ask that everyone using our internet sites and services do the same. Anyone who believes that their work has been reproduced in one of our internet sites or services in a way that constitutes copyright infringement may notify mod.io's copyright agent using the form below.",
    dmca_2:
      'Submitting a claim of copyright infringement is a serious legal matter. Before you proceed, you might consider contacting the individual directly to address the complaint. It might be a simple misunderstanding and possible to address without involving proper legal process.',
    dmca_3:
      'Be aware that under Section 512(f) of the Digital Millennium Copyright Act, any person who knowingly materially misrepresents that material or activity is infringing may be liable for damages. Please also note that the information provided in this legal notice may be shared with third-parties or made public.',
    claim_check:
      'By checking the following boxes and submitting this claim, I state UNDER PENALTY OF PERJURY that',
    copyright_owner: 'I am the Copyright Owner',
    copyright_auth_owner:
      "I am authorized to act on the copyright owner's behalf in this situation.",
    authorization: 'Authorization',
    authorization_check:
      'I have a good faith belief that the use of the material noted in this claim is not authorized by the copyright owner, its agent, or the law.',
    acknowledgement: 'Acknowledgement',
    acknowledgement_check:
      'I acknowledge that under Section 512(f) of the DMCA any person who knowingly materially misrepresent that material or activity is infringing may be liable for damages.',
    required: 'required',
    signature: 'Signature',
    signature_placeholder:
      'Type your full name into this box, acting as your digital signature.',
    report_success_title: 'Your report has been received',
    report_success_text:
      'If your message requires a response, please be patient as we endeavour to reply to all reports within 48 hours of receiving them.',
    report_error_title: 'Failed to submit report',

    // Table
    name: 'Name',
    added: 'Added',
    updated: 'Updated',
    file: 'File',
    version: 'Version',
    actions: 'Actions',
    locally_installed: 'Locally installed',

    // Releases
    download_from_game_client: 'Download {mod} from game client',
    release_notes: 'Release notes',
    no_release_notes: 'No release notes provided',
    previous_releases: 'Previous releases',
    no_previous_releases: 'There are no previous releases',
    no_files_published: 'No files published',

    // Ratings
    overwhelmingly_positive: 'Overwhelmingly positive',
    overwhelmingly_negative: 'Overwhelmingly negative',
    very_positive: 'Very positive',
    very_negative: 'Very negative',
    mostly_positive: 'Mostly positive',
    mostly_negative: 'Mostly negative',
    negative: 'Negative',
    positive: 'Positive',
    mixed: 'Mixed',
    unrated: 'Unrated',

    // Sort
    sort_comments: 'Comments',
    sort_total_mods: 'Total Mods',
    sort_trending: 'Trending',
    sort_highest_rated: 'Highest Rated',
    sort_most_popular: 'Most Popular',
    sort_most_subscribers: 'Most Subscribers',
    sort_recently_added: 'Recently Added',
    sort_last_updated: 'Last Updated',
    sort_alphabetical: 'Alphabetical',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Not Working',
    report_rude_content: 'Rude Content',
    report_illegal_content: 'Illegal Content',
    report_stolen_content: 'Stolen Content',
    report_false_information: 'False Information',
    report_other: 'Other',

    // Errors
    login_fail: 'Failed to login',
    login_fail_message: 'Failed to login. Please wait a minute and try again.',
    subscribe_fail: 'Failed to subscribe',
    unsubscribe_fail: 'Failed to unsubscribe',
  },
  fr: {
    language: 'Langue',

    // Nav
    browse: 'Parcourir',
    collection: 'Ma collection',
    back_to_browse: 'Retourner',

    // Mods
    mod: 'Mod | Mods',
    content: 'Contenu',
    subscribed: 'Abonné',
    tags: 'Tags',
    summary: 'Résumé',
    contributors: 'Contributeurs',
    description: 'Description',
    dependencies: 'Dépendances',
    of: 'sur',
    reset_filters: 'Réinitialiser les filtres',
    no_results_found: 'Aucun {ugc_name} trouvé',
    no_subscriptions: "Vous n'êtes abonné à aucun {ugc_name}",
    mod_disclaimer_heading: 'Mentions légales',
    mod_disclaimer_text:
      "Vous êtes sur le point de télécharger un mod, dont vous convenez qu'il est exclusivement utilisable dans l'écosystème d'Anno 1800, tel que prescrit par Ubisoft. Ubisoft n'est pas responsable du contenu des fichiers ou téléchargements associés. À ce titre, il ne saurait garantir la sécurité, la qualité ou la stabilité des mods, ni fournir la moindre assistance dans le cadre de leur utilisation. Si vous choisissez de continuer, c'est votre seule responsabilité que vous engagez. Ubisoft se réserve le droit de faire supprimer des mods à tout moment et pour quelque raison que ce soit. Ubisoft veille tout particulièrement à la sécurité de vos données personnelles. Pour optimiser votre utilisation des mods (téléversement, téléchargement et signalement, notamment), Ubisoft assure le traitement de vos données personnelles. Pour en savoir plus sur le traitement de vos données personnelles par Ubisoft et sur les moyens d'exercer vos droits, reportez-vous à notre {mod_disclaimer_privacy}.",
    mod_disclaimer_privacy: "Charte d'utilisation des données personnelles",

    // Actions
    login: 'Connexion',
    logout: 'Déconnexion',
    search: 'Recherche',
    clear_all: 'Réinitialiser',
    download: 'Télécharger',
    subscribe: "S'abonner",
    unsubscribe: 'Se désabonner',
    resubscribe: 'Se réabonner',
    activate: 'Activer',
    deactivate: 'Désactiver',
    like: "J'aime",
    dislike: "Je n'aime pas",
    report: 'Signaler',
    submit: 'Envoyer',
    cancel: 'Annuler',

    // Filters
    all: 'Tous',
    available: 'disponible(s)',
    filesize: 'Taille du fichier',
    downloads: 'Téléchargements',
    todays_downloads: 'Téléchargements du jour',
    subscribers: 'Abonnés',
    rating: 'Notation',
    sort_by: 'Trier par',
    creator: 'Créateur',

    // Login
    signup: 'Connectez-vous ou inscrivez-vous avec votre adresse e-mail',
    email_placeholder: 'Adresse e-mail',
    code_placeholder: 'Code à 5 chiffres',
    have_code: "J'ai un code",
    email_code: 'Envoyer un code par e-mail',
    send_new_code: 'Envoyer un nouveau code',
    check_email:
      'Veuillez consulter votre boîte e-mail et saisissez ici le code reçu.',
    login_disclaimer:
      'Vous vous connectez à mod.io, solution utilisée pour prendre en charge les créations réalisées par les joueurs.',
    terms_disclaimer:
      'En continuant, vous acceptez les {terms} et la {privacy} et devez avoir 13 ans ou plus.',
    terms: "Conditions d'utilisation",
    privacy: 'Politique de confidentialité',
    code_error: 'Demande de code impossible.',

    // Report
    report_heading: 'Signaler ce mod',
    report_intro:
      "Signalez du contenu qui ne respecte pas les {terms} du site ou envoyez une {dmca} en utilisant le formulaire ci-dessous. Assurez-vous d'inclure toutes les informations et tous les liens pertinents.",
    dmca: 'Plainte propriété intellectuelle',
    reason: 'Raison du signalement',
    urls: 'URL incriminées',
    urls_placeholder: 'URL (1 par ligne)',
    company_or_name: 'Société ou votre nom',
    email: 'Adresse e-mail',
    address: 'Adresse',
    country: 'Pays',
    country_placeholder: 'Sélectionnez un pays',
    details: "Détails de l'infraction",
    details_placeholder:
      "Pour nous aider à traiter votre signalement, veuillez fournir autant de détails et de preuves que possible, y compris:\n\nLes URL, les dates, les noms des comptes impliqués\nOù trouver le contenu original\nL'infraction qui s'est produite",
    dmca_1:
      "{mod_io} respecte les droits de propriété intellectuelle d'autrui et nous demandons à tous ceux qui utilisent nos sites et services Internet de faire de même. Toute personne qui estime que son travail a été reproduit sur l'un de nos sites Internet ou services d'une manière qui constitue une violation du droit d'auteur peut en informer l'agent des droits d'auteur de mod.io en utilisant le formulaire ci-dessous.",
    dmca_2:
      "Soumettre une réclamation pour infraction au droit d'auteur est une affaire juridique sérieuse. Avant de poursuivre, vous pouvez envisager de contacter directement la personne pour résoudre la plainte. Il peut s'agir d'un simple malentendu qui puisse être résolu sans impliquer une procédure judiciaire appropriée.",
    dmca_3:
      "Sachez qu'en vertu de l'article 512 (f) de la loi Digital Millennium Copyright Act, toute personne qui déclare faussement, sciemment et/ou matériellement que le matériel ou l'activité est en infraction peut être tenue responsable des dommages. Veuillez également noter que les informations fournies dans la présente notice légale peuvent être partagées avec des tiers ou rendues publiques.",
    claim_check:
      'En cochant les cases suivantes et en soumettant cette réclamation, je déclare SOUS PEINE DE PARJURE que',
    copyright_owner: "Je suis le titulaire du droit d'auteur",
    copyright_auth_owner:
      "J'ai l'autorisation d'agir au nom du titulaire du droit d'auteur dans cette situation.",
    authorization: 'Autorisation',
    authorization_check:
      "Je crois en toute bonne foi que l'utilisation du matériel indiqué dans cette réclamation n'est pas autorisée par le titulaire du droit d'auteur, son agent ou la loi.",
    acknowledgement: 'Reconnaissance',
    acknowledgement_check:
      "Je reconnais qu'en vertu de l'article 512 (f) du DMCA, toute personne qui déclare faussement, sciemment et/ou matériellement que le matériel ou l'activité est en infraction peut être tenue responsable des dommages.",
    required: 'requis',
    signature: 'Signature',
    signature_placeholder:
      'Saisissez votre nom complet dans cette case, agissant comme votre signature numérique.',
    report_success_title: 'Votre signalement a été reçu',
    report_success_text:
      'Si votre message nécessite une réponse, veuillez patienter car nous nous efforçons de répondre à tous les signalements dans les 48 heures suivant leur réception.',
    report_error_title: "Échec de l'envoi du signalement",

    // Table
    name: 'Nom',
    added: 'Ajouté',
    updated: 'Mis à jour',
    file: 'Fichier',
    version: 'Version',
    actions: 'Actions',
    locally_installed: 'Installé localement',

    // Releases
    download_from_game_client: 'Télécharger {mod} depuis le client du jeu',
    release_notes: 'Notes de version',
    no_release_notes: 'Aucune note de version fournie',
    previous_releases: 'Versions précédentes',
    no_previous_releases: 'Aucune version précédente',
    no_files_published: 'Aucun fichier publié',

    // Ratings
    overwhelmingly_positive: 'Extrêmement positif',
    overwhelmingly_negative: 'Extrêmement négatif',
    very_positive: 'Très positif',
    very_negative: 'Très négatif',
    mostly_positive: 'Plutôt positif',
    mostly_negative: 'Plutôt négatif',
    negative: 'Négatif',
    positive: 'Positif',
    mixed: 'Mixte',
    unrated: 'Non classé',

    // Sort
    sort_comments: 'Commentaires',
    sort_total_mods: 'Mods totaux',
    sort_trending: 'Tendance',
    sort_highest_rated: 'Les mieux notés',
    sort_most_popular: 'Le plus populaire',
    sort_most_subscribers: "Le plus d'abonnés",
    sort_recently_added: 'Récemment ajouté',
    sort_last_updated: 'Dernière mise à jour',
    sort_alphabetical: 'Alphabétique',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Ne fonctionne pas',
    report_rude_content: 'Contenu inapproprié',
    report_illegal_content: 'Contenu illégal',
    report_stolen_content: 'Contenu volé',
    report_false_information: 'Informations fausses',
    report_other: 'Autre',

    // Errors
    login_fail: 'Échec de la connexion',
    login_fail_message:
      'Échec de la connexion. Veuillez attendre une minute et réessayer.',
    subscribe_fail: "Échec de l'abonnement",
    unsubscribe_fail: 'Échec de la désabonnement',
  },
  de: {
    language: 'Sprache',

    // Nav
    browse: 'Durchsuchen',
    collection: 'Meine Sammlung',
    back_to_browse: 'Geh zurück',

    // Mods
    mod: 'Mod | Mods',
    content: 'Inhalt',
    subscribed: 'Abonniert',
    tags: 'Tags',
    summary: 'Zusammenfassung',
    contributors: 'Mitwirkende',
    description: 'Beschreibung',
    dependencies: 'Abhängigkeiten',
    of: 'von',
    reset_filters: 'Filter zurücksetzen',
    no_results_found: 'Kein {ugc_name} gefunden',
    no_subscriptions: 'Sie haben kein {ugc_name} abonniert',
    mod_disclaimer_heading: 'Rechtlicher Hinweis',
    mod_disclaimer_text:
      'Du bist im Begriff, eine Mod herunterzuladen und erklärst dich damit einverstanden, dass sie nur im Rahmen von Anno 1800 verwendet werden darf, wie von Ubisoft ausdrücklich erlaubt. Ubisoft ist nicht verantwortlich für die Inhalte von Mod-Dateien oder Downloads und kann weder die Sicherheit, Qualität oder Stabilität von Mods garantieren noch Support für Mods bieten. Wenn du dich entscheidest, fortzufahren, dann geschieht das auf eigenes Risiko. Ubisoft behält sich das Recht vor, Mods jederzeit ohne Angabe von Gründen zu entfernen.Ubisoft verpflichtet sich, deine persönlichen Daten zu schützen. Um dir das bestmögliche Erlebnis bei der Verwendung von Mods bieten zu können, wozu auch das Hochladen, Herunterladen und Melden von Mods gehört, verarbeitet Ubisoft deine persönlichen Daten. Weitere Informationen darüber, wie Ubisoft deine persönlichen Daten verwendet, und wie du deine Rechte wahrnehmen kannst, findest du in unserer {mod_disclaimer_privacy}.',
    mod_disclaimer_privacy: 'Datenschutzerklärung',

    // Actions
    login: 'Anmelden',
    logout: 'Abmelden',
    search: 'Suchen',
    clear_all: 'Alle löschen',
    download: 'Herunterladen',
    subscribe: 'Abonnieren',
    unsubscribe: 'Nicht mehr abonnieren',
    resubscribe: 'Erneut abonnieren',
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
    like: 'Gefällt mir',
    dislike: 'Gefällt mir nicht',
    report: 'Melden',
    submit: 'Absenden',
    cancel: 'Abbrechen',

    // Filters
    all: 'Alle',
    available: 'verfügbar',
    filesize: 'Dateigröße',
    downloads: 'Downloads',
    todays_downloads: 'Heutige Downloads',
    subscribers: 'Abonnenten',
    rating: 'Bewertung',
    sort_by: 'Sortieren nach',
    creator: 'Creator',

    // Login
    signup: 'Anmelden oder mit E-Mail-Adresse registrieren',
    email_placeholder: 'E-Mail-Adresse',
    code_placeholder: '5-stelliger Code',
    have_code: 'Ich habe einen Code',
    email_code: 'Code per E-Mail senden',
    send_new_code: 'Neuen Code senden',
    check_email:
      'Bitte prüfen Sie Ihr E-Mail-Postfach auf Ihren Code und geben Sie ihn hier ein.',
    login_disclaimer:
      'Sie melden sich bei mod.io an, das zur Unterstützung von nutzergenerierten Inhalten im Spiel verwendet wird.',
    terms_disclaimer:
      'Wenn Sie fortfahren, stimmen Sie den {terms} und der {privacy} zu. Sie müssen mindestens 13 Jahre alt sein.',
    terms: 'Nutzungsbedingungen',
    privacy: 'Datenschutzerklärung',
    code_error: 'Code kann nicht angefordert werden.',

    // Report
    report_heading: 'Diese Mod melden',
    report_intro:
      'Melden Sie Inhalte, die gegen die {terms} der Website verstoßen, oder reichen Sie eine {dmca} ein, indem Sie das Formular unten verwenden. Stellen Sie sicher, dass Sie alle relevanten Informationen und Links angeben.',
    dmca: 'DMCA-Beschwerde',
    reason: 'Grund für die Meldung',
    urls: 'Problematische URLs',
    urls_placeholder: 'URLs (eine pro Zeile)',
    company_or_name: 'Unternehmen oder Ihr Name',
    email: 'E-Mail',
    address: 'Adresse',
    country: 'Land',
    country_placeholder: 'Wählen Sie ein Land aus',
    details: 'Einzelheiten zum Verstoß',
    details_placeholder:
      'Um uns bei der Bearbeitung Ihrer Meldung zu helfen, geben Sie bitte so viele Details und Nachweise wie möglich an, einschließlich:\n\nder URLs, Daten, Namen der beteiligten Konten\ndes Orts, an dem der Originalinhalt gefunden werden kann\ndes Verstoßes, der stattgefunden hat',
    dmca_1:
      '{mod_io} respektiert die geistigen Eigentumsrechte anderer und wir bitten darum, dass jeder, der unsere Internet-Websites und Dienste nutzt, dies ebenfalls tut. Wenn Sie der Meinung sind, dass Ihr Werk auf einer unserer Internet-Websites oder im Rahmen eines unserer Dienste in einer Weise reproduziert wurde, die eine Urheberrechtsverletzung darstellt, können Sie den Vertreter für Urheberrechte bei mod.io über das unten stehende Formular informieren.',
    dmca_2:
      'Das Einreichen einer Beschwerde wegen Urheberrechtsverletzung ist eine ernste rechtliche Angelegenheit. Bevor Sie fortfahren, sollten Sie erwägen, die Person direkt zu kontaktieren, um das Problem anzusprechen. Möglicherweise handelt es sich um ein einfaches Missverständnis, das sich abseits des Rechtswegs klären lässt.',
    dmca_3:
      'Bitte beachten Sie, dass gemäß Abschnitt 512 (f) des Digital Millennium Copyright Act jede Person, die wissentlich falsche Angaben über die Rechtsverletzung von Material oder Aktivitäten macht, schadensersatzpflichtig sein kann. Bitte beachten Sie weiterhin, dass die in diesem rechtlichen Hinweis enthaltenen Informationen an Dritte weitergegeben oder veröffentlicht werden können.',
    claim_check:
      'Indem ich die folgenden Kästchen ankreuze und diese Beschwerde einreiche, erkläre ich UNTER ANDROHUNG VON STRAFE FÜR SCHULDHAFTES VERHALTEN, dass',
    copyright_owner: 'ich der Urheberrechtsinhaber bin',
    copyright_auth_owner:
      'ich berechtigt bin, in dieser Situation im Namen des Urheberrechtsinhabers zu handeln.',
    authorization: 'Genehmigung',
    authorization_check:
      'Ich bin in gutem Glauben davon überzeugt, dass die Verwendung des in dieser Beschwerde genannten Materials nicht durch den Urheberrechtsinhaber, dessen Vertreter oder das Gesetz genehmigt ist.',
    acknowledgement: 'Bestätigung',
    acknowledgement_check:
      'Ich erkenne an, dass gemäß Abschnitt 512 (f) des DMCA jede Person, die wissentlich falsche Angaben über die Rechtsverletzung von Material oder Aktivitäten macht, schadensersatzpflichtig sein kann.',
    required: 'erforderlich',
    signature: 'Unterschrift',
    signature_placeholder:
      'Geben Sie Ihren vollständigen Namen in dieses Feld ein, was als Ihre digitale Unterschrift dient.',
    report_success_title: 'Ihre Meldung ist eingegangen',
    report_success_text:
      'Falls Ihre Nachricht eine Antwort erfordert, haben Sie bitte etwas Geduld. Wir bemühen uns, alle Meldungen innerhalb von 48 Stunden nach Erhalt zu beantworten.',
    report_error_title: 'Fehler beim Senden der Meldung',

    // Table
    name: 'Name',
    added: 'Hinzugefügt',
    updated: 'Aktualisiert',
    file: 'Datei',
    version: 'Version',
    actions: 'Aktionen',
    locally_installed: 'Lokal installiert',

    // Releases
    download_from_game_client: '{mod} im Spielclient herunterladen',
    release_notes: 'Versionshinweise',
    no_release_notes: 'Keine Versionshinweise verfügbar',
    previous_releases: 'Vorherige Versionen',
    no_previous_releases: 'Es gibt keine vorherigen Versionen',
    no_files_published: 'Keine Dateien veröffentlicht',

    // Ratings
    overwhelmingly_positive: 'Äußerst positiv',
    overwhelmingly_negative: 'Äußerst negativ',
    very_positive: 'Sehr positiv',
    very_negative: 'Sehr negativ',
    mostly_positive: 'Größtenteils positiv',
    mostly_negative: 'Größtenteils negativ',
    negative: 'Negativ',
    positive: 'Positiv',
    mixed: 'Gemischt',
    unrated: 'Keine Bewertung',

    // Sort
    sort_comments: 'Kommentare',
    sort_total_mods: 'Gesamtzahl der Mods',
    sort_trending: 'Im Trend',
    sort_highest_rated: 'Am besten bewertet',
    sort_most_popular: 'Am beliebtesten',
    sort_most_subscribers: 'Die meisten Abonnenten',
    sort_recently_added: 'Kürzlich hinzugefügt',
    sort_last_updated: 'Letzte Aktualisierung',
    sort_alphabetical: 'Alphabetisch',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Funktioniert nicht',
    report_rude_content: 'Unhöflicher Inhalt',
    report_illegal_content: 'Illegaler Inhalt',
    report_stolen_content: 'Gestohlener Inhalt',
    report_false_information: 'Falsche Informationen',
    report_other: 'Andere',

    // Errors
    login_fail: 'Einloggen fehlgeschlagen',
    login_fail_message:
      'Einloggen fehlgeschlagen. Bitte warten Sie eine Minute und versuchen Sie es erneut.',
    subscribe_fail: 'Nicht abonnieren',
    unsubscribe_fail: 'Versäumnis, sich abzumelden',
  },
  it: {
    language: 'Lingua',

    // Nav
    browse: 'Esplora',
    collection: 'La mia raccolta',
    back_to_browse: 'Torna indietro',

    // Mods
    mod: 'Mod | Mods',
    content: 'Contenuto',
    subscribed: 'Iscritto',
    tags: 'Tag',
    summary: 'Riepilogo',
    contributors: 'Collaboratori',
    description: 'Descrizione',
    dependencies: 'Dipendenze',
    of: 'di',
    reset_filters: 'Reimposta filtri',
    no_results_found: 'Nessun {ugc_name} trovato',
    no_subscriptions: 'Non sei abbonato a nessun {ugc_name}',
    mod_disclaimer_heading: 'Dichiarazione di non responsabilità',
    mod_disclaimer_text:
      "Stai per scaricare una mod, accettando che possa essere utilizzata solo all'interno dell'ecosistema di Anno 1800 come specificamente consentito da Ubisoft. Ubisoft non è responsabile del download o del contenuto di alcun file mod e non può garantirne la sicurezza, la qualità o la stabilità, né fornire supporto per esse. Se scegli di continuare, lo fai a tuo rischio e pericolo. Ubisoft si riserva il diritto di far rimuovere le mod in qualsiasi momento e per qualsiasi motivo. Ubisoft si impegna a proteggere i tuoi dati personali. Ubisoft elabora i tuoi dati personali per offrirti la migliore esperienza possibile nell'uso delle mod, inclusi il caricamento, il download e la segnalazione delle mod. Per ulteriori informazioni su come Ubisoft elabora i tuoi dati personali e su come puoi esercitare i tuoi diritti, leggi la nostra {mod_disclaimer_privacy}.",
    mod_disclaimer_privacy: 'Informativa sulla privacy',

    // Actions
    login: 'Accedi',
    logout: 'Esci',
    search: 'Cerca',
    clear_all: 'Cancella tutto',
    download: 'Scarica',
    subscribe: 'Iscriviti',
    unsubscribe: "Annulla l'iscrizione",
    resubscribe: "Ripristina l'iscrizione",
    activate: 'Attiva',
    deactivate: 'Disattiva',
    like: 'Mi piace',
    dislike: 'Non mi piace',
    report: 'Segnala',
    submit: 'Invia',
    cancel: 'Annulla',

    // Filters
    all: 'Tutto',
    available: 'disponibili',
    filesize: 'Dimensione file',
    downloads: 'Download',
    todays_downloads: 'I download di oggi',
    subscribers: 'Iscritti',
    rating: 'Valutazione',
    sort_by: 'Ordina per',
    creator: 'Creatore',

    // Login
    signup: 'Accedi o registrati con email',
    email_placeholder: 'Indirizzo email',
    code_placeholder: 'Codice a 5 cifre',
    have_code: 'Ho un codice',
    email_code: 'Codice email',
    send_new_code: 'Invia nuovo codice',
    check_email: 'Recuperare il codice nella casella di posta e inserirlo qui.',
    login_disclaimer:
      "Si sta effettuando l'accesso a mod.io, sito di supporto per i contenuti di gioco generati dagli utenti.",
    terms_disclaimer:
      "Continuando, si accettano i {terms} e l'{privacy}. Per utilizzare il servizio è necessario aver compiuti i 13 anni di età.",
    terms: "Termini d'uso",
    privacy: 'Informativa sulla privacy',
    code_error: 'Impossibile richiedere il codice.',

    // Report
    report_heading: 'Segnala questa mod',
    report_intro:
      'Segnala un contenuto che viola i {terms} del sito oppure inoltra un {dmca} attraverso il modulo sottostante. Includi tutte le informazioni e i link pertinenti.',
    dmca: 'Reclamo DMCA',
    reason: 'Motivo della segnalazione',
    urls: 'URL della violazione',
    urls_placeholder: 'URL (1 per riga)',
    company_or_name: "Nome proprio o dell'azienda",
    email: 'Email',
    address: 'Indirizzo',
    country: 'Paese',
    country_placeholder: 'Selezionare un Paese',
    details: 'Informazioni sulla violazione',
    details_placeholder:
      "Per facilitare l'elaborazione della segnalazione, inserire più informazioni e prove possibili, inclusi:\n\nURL, date, nomi account rilevanti\nPosizione del contenuto originale\nLa violazione commessa",
    dmca_1:
      "{mod_io} rispetta i diritti di proprietà intellettuale altrui e richiede altrettanto a tutti coloro che utilizzano i suoi siti internet e servizi. Chiunque ritenga che la propria opera sia stata riprodotta in uno dei nostri siti internet o servizi in modo tale da costituire una violazione del copyright, può segnalarlo all'agente designato al DMCA di mod.io attraverso il modulo sottostante.",
    dmca_2:
      'Inoltrare un reclamo per violazione del copyright presenta serie implicazioni giuridiche. Prima di procedere, valutare la possibilità di sporgere reclamo al diretto interessato contattandolo personalmente. Potrebbe trattarsi di un semplice malinteso risolvibile senza il ricorso a provvedimenti legali.',
    dmca_3:
      "Nota bene: secondo il Digital Millennium Copyright Act, Sezione 512 (f), chiunque consapevolmente e sostanzialmente dichiari il falso sulla violazione di un materiale o un'attività, può essere chiamato a rispondere di eventuali danni. Inoltre, le informazioni fornite sotto la presente nota legale potranno essere condivise con terze parti o rese pubbliche.",
    claim_check:
      'Selezionando le seguenti caselle e inviando il presente reclamo, dichiaro SOTTO PENA DI SPERGIURO che',
    copyright_owner: 'Sono il titolare del copyright',
    copyright_auth_owner:
      "Ho l'autorizzazione ad agire per conto del titolare del copyright nelle presenti circostanze.",
    authorization: 'Autorizzazione',
    authorization_check:
      "Ritengo in buona fede che l'utilizzo del materiale segnalato in questo reclamo non sia autorizzato dal titolare del copyright, dal suo agente o dalla legge.",
    acknowledgement: 'Riconoscimento',
    acknowledgement_check:
      "Riconosco che secondo il DMCA, Sezione 512 (f), chiunque consapevolmente e sostanzialmente dichiari il falso sulla violazione di un materiale o un'attività, può essere chiamato a rispondere di eventuali danni.",
    required: 'campo obbligatorio',
    signature: 'Firma',
    signature_placeholder:
      'Digitare nome e cognome nel campo sottostante. Fungerà da firma digitale.',
    report_success_title: 'La segnalazione è stata ricevuta',
    report_success_text:
      'Se il messaggio inviato richiede risposta, si prega di attendere. Ci adoperiamo per rispondere a tutte le segnalazioni entro 48 ore dalla ricezione.',
    report_error_title: 'Impossibile inviare la segnalazione',

    // Table
    name: 'Nome',
    added: 'Periodo pubblicazione',
    updated: 'Aggiornamento',
    file: 'File',
    version: 'Versione',
    actions: 'Azioni',
    locally_installed: 'Installazione locale',

    // Releases
    download_from_game_client: 'Scarica {mod} dal client di gioco',
    release_notes: 'Note di versione',
    no_release_notes: 'Nessuna nota di versione',
    previous_releases: 'Versioni precedenti',
    no_previous_releases: 'Nessuna versione precedente',
    no_files_published: 'Nessun file pubblicato',

    // Ratings
    overwhelmingly_positive: 'Estremamente positivo',
    overwhelmingly_negative: 'Estremamente negativo',
    very_positive: 'Molto positivo',
    very_negative: 'Molto negativo',
    mostly_positive: 'Prevalentemente positivo',
    mostly_negative: 'Prevalentemente negativo',
    negative: 'Negativo',
    positive: 'Positivo',
    mixed: 'Ambivalente',
    unrated: 'Non classificato',

    // Sort
    sort_comments: 'Commenti',
    sort_total_mods: 'Mod totali',
    sort_trending: 'Di tendenza',
    sort_highest_rated: 'Voto più alto',
    sort_most_popular: 'Più popolare',
    sort_most_subscribers: 'La maggior parte degli abbonati',
    sort_recently_added: 'Aggiunto recentemente',
    sort_last_updated: 'Ultimo aggiornamento',
    sort_alphabetical: 'Alfabetico',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Non funzionante',
    report_rude_content: 'Contenuti maleducati',
    report_illegal_content: 'Contenuto illegale',
    report_stolen_content: 'Contenuto rubato',
    report_false_information: 'Informazioni false',
    report_other: 'Altro',

    // Errors
    login_fail: 'Impossibile accedere',
    login_fail_message:
      'Impossibile accedere. Per favore aspetta un minuto e riprova.',
    subscribe_fail: 'Impossibile iscriversi',
    unsubscribe_fail: "Impossibile annullare l'iscrizione",
  },
  es: {
    language: 'Idioma',

    // Nav
    browse: 'Explorar',
    collection: 'Mi colección',
    back_to_browse: 'Regresa',

    // Mods
    mod: 'Mod | Mods',
    content: 'Contenido',
    subscribed: 'Suscrito',
    tags: 'Etiquetas',
    summary: 'Resumen',
    contributors: 'Contribuciones',
    description: 'Descripción',
    dependencies: 'Dependencias',
    of: 'de',
    reset_filters: 'Restablecer filtros',
    no_results_found: 'No se encontró {ugc_name}',
    no_subscriptions: 'No te has suscrito a ningún {ugc_name}',
    mod_disclaimer_heading: 'Aviso legal',
    mod_disclaimer_text:
      'Vas a descargar un mod, y aceptas usarlo únicamente en el ecosistema de Anno 1800 tal y como permite Ubisoft en términos concretos. Ubisoft no es responsable por el contenido de ningún archivo ni descargas de mods y no puede garantizar la seguridad, calidad ni estabilidad de los mods, ni tampoco proporcionar ningún tipo de apoyo para ellos. Si decides continuar, es bajo tu propia cuenta y riesgo. Ubisoft se reserva el derecho a eliminar mods en cualquier momento por cualquier motivo. Ubisoft se compromete a proteger tus datos personales. Para proporcionarte la mejor experiencia posible al usar mods, incluyendo su subida, descarga y denuncia, Ubisoft procesa tus datos personales. Para más información sobre el procesamiento de tus datos personales y cómo ejercer tus derechos, lee nuestra {mod_disclaimer_privacy}.',
    mod_disclaimer_privacy: 'Política de privacidad',

    // Actions
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    search: 'Buscar',
    clear_all: 'Borrar todo',
    download: 'Descargar',
    subscribe: 'Suscribirse',
    unsubscribe: 'Anular suscripción',
    resubscribe: 'Volver a suscribirse',
    activate: 'Activar',
    deactivate: 'Desactivar',
    like: 'Me gusta',
    dislike: 'No me gusta',
    report: 'Denunciar',
    submit: 'Enviar',
    cancel: 'Cancelar',

    // Filters
    all: 'Todo',
    available: 'disponible(s)',
    filesize: 'Tamaño de archivo',
    downloads: 'Descargas',
    todays_downloads: 'Descargados hoy',
    subscribers: 'Suscriptores',
    rating: 'Valuación',
    sort_by: 'Ordenar por',
    creator: 'Creador',

    // Login
    signup: 'Inicie sesión o regístrese con correo electrónico',
    email_placeholder: 'Dirección de correo electrónico',
    code_placeholder: 'Código de 5 dígitos',
    have_code: 'Tengo un código',
    email_code: 'Enviar código al correo',
    send_new_code: 'Enviar otro código',
    check_email:
      'Busque el correo electrónico con el código e introdúzcalo aquí.',
    login_disclaimer:
      'Está iniciando sesión en mod.io, un lugar donde se apoya el contenido de juego que crean los usuarios.',
    terms_disclaimer:
      'Al continuar, acepta los {terms} y la {privacy}, y confirma que tiene 13 años o más.',
    terms: 'Términos de uso',
    privacy: 'Política de privacidad',
    code_error: 'No se ha podido solicitar el código.',

    // Report
    report_heading: 'Denunciar este mod',
    report_intro:
      'Denuncie el contenido que no cumpla con los {terms} de esta página o envíe una {dmca} a través de este formulario. Incluya toda la información y enlaces relevantes.',
    dmca: 'queja DMCA',
    reason: 'Motivo de la denuncia',
    urls: 'URL ilícita',
    urls_placeholder: 'URL (1 por línea)',
    company_or_name: 'Empresa o nombre',
    email: 'Correo electrónico',
    address: 'Dirección',
    country: 'País',
    country_placeholder: 'Selecciona un país',
    details: 'Detalles de la infracción',
    details_placeholder:
      'Para que procesemos mejor su denuncia, proporcione toda la información y las pruebas posibles:\n\nURL, fechas, nombre de las cuentas involucradas\ndónde podemos encontrar el material original\nel tipo de infracción que se ha cometido',
    dmca_1:
      '{mod_io} respeta los derechos de propiedad intelectual de todas las personas, por lo que pedimos a quienes hacen uso de nuestros sitios web que también respeten dichos derechos. Cualquier persona que considere que su trabajo se ha replicado en alguno de nuestros sitios web o servicios de manera que constituya un incumplimiento de los derechos de copyright debe notificar al intermediario de derechos copyright de mod.io a través del siguiente formulario.',
    dmca_2:
      'Presentar una reclamación por infracción de los derechos de copyright es un asunto legal grave. Antes de continuar, intente contactar directamente con la persona para remitir directamente su queja. Es posible que se trate de un malentendido y se pueda resolver sin recurrir a un proceso judicial.',
    dmca_3:
      'Tenga en cuenta que, según la sección 512(f) de la Digital Millennium Copyright Act (La Ley de Derechos de Autor de la Era Digital), cualquier persona que informe intencionalmente y sin fundamento acerca de material o actividad ilícita, podrá declararse responsable de los daños. Tenga en cuenta también que la información que proporcione en este aviso legal podrá hacerse pública o compartirse con terceros.',
    claim_check:
      'Al seleccionar las siguientes casillas y enviar esta reclamación declaro BAJO PENA DE PERJURIO que',
    copyright_owner: 'Soy titular de los derechos de Copyright',
    copyright_auth_owner:
      'Tengo autoridad para actuar en nombre del titular de los derechos de copyright en este caso.',
    authorization: 'Autorización',
    authorization_check:
      'Creo de buena fe que el uso del material mencionado en esta reclamación no cuenta con autorización del titular de los derechos de copyright, su representante o la ley.',
    acknowledgement: 'Confirmación',
    acknowledgement_check:
      'Reconozco que, según la sección 512(f) del DMCA, cualquier persona que informe intencionalmente y sin fundamentos de material o actividad ilícito, podrá declararse responsable de los daños.',
    required: 'obligatorio',
    signature: 'Firma',
    signature_placeholder:
      'Escriba su nombre completo en este cuadro como firma digital.',
    report_success_title: 'Hemos recibido su denuncia.',
    report_success_text:
      'Si su mensaje requiere una respuesta, le pedimos que tenga paciencia. Tratamos de responder todas las denuncias en un plazo de 48 horas desde que las recibimos.',
    report_error_title: 'Error al enviar la denuncia',

    // Table
    name: 'Nombre',
    added: 'Añadida',
    updated: 'Actualizada',
    file: 'Archivo',
    version: 'Versión',
    actions: 'Medidas',
    locally_installed: 'Instalado en local',

    // Releases
    download_from_game_client: 'Descargar {mod} desde el cliente del juego',
    release_notes: 'Notas de la versión',
    no_release_notes: 'No se han proporcionado notas de la versión',
    previous_releases: 'Versiones anteriores',
    no_previous_releases: 'No hay versiones anteriores',
    no_files_published: 'No se han publicado archivos',

    // Ratings
    overwhelmingly_positive: 'Extremadamente positivo',
    overwhelmingly_negative: 'Extremadamente negativo',
    very_positive: 'Muy positivo',
    very_negative: 'Muy negativo',
    mostly_positive: 'Generalmente positivo',
    mostly_negative: 'Generalmente negativo',
    negative: 'Negativo',
    positive: 'Positivo',
    mixed: 'Variado',
    unrated: 'Sin clasificar',

    // Sort
    sort_comments: 'Comentarios',
    sort_total_mods: 'Modos totales',
    sort_trending: 'Tendencias',
    sort_highest_rated: 'El mejor valorado',
    sort_most_popular: 'Más popular',
    sort_most_subscribers: 'La mayoría de los suscriptores',
    sort_recently_added: 'Recientemente añadido',
    sort_last_updated: 'Última actualización',
    sort_alphabetical: 'Alfabético',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'No funciona',
    report_rude_content: 'Contenido grosero',
    report_illegal_content: 'Contenido ilegal',
    report_stolen_content: 'Contenido Robado',
    report_false_information: 'Información Falsa',
    report_other: 'Otro',

    // Errors
    login_fail: 'Error al iniciar sesión',
    login_fail_message:
      'Error al iniciar sesión. Espere un minuto y vuelva a intentarlo.',
    subscribe_fail: 'No se suscribió',
    unsubscribe_fail: 'No se puso de baja',
  },
  pl: {
    language: 'Język',

    // Nav
    browse: 'Przeglądaj',
    collection: 'Moja kolekcja',
    back_to_browse: 'Wróć',

    // Mods
    mod: 'Mod | Mods',
    content: 'Zawartość',
    subscribed: 'Subskrybujesz',
    tags: 'Tagi',
    summary: 'Podsumowanie',
    contributors: 'Współtwórcy',
    description: 'Opis',
    dependencies: 'Zależności',
    of: 'z',
    reset_filters: 'Zresetuj filtry',
    no_results_found: 'Nie znaleziono {ugc_name}',
    no_subscriptions: 'Nie zasubskrybowałeś żadnego {ugc_name}',
    mod_disclaimer_heading: 'Wyłączenie odpowiedzialności',
    mod_disclaimer_text:
      'Zamierzasz pobrać modyfikację (mod), z której można korzystać wyłącznie w ekosystemie Anno 1800, zgodnie z wyraźnym zezwoleniem Ubisoft. Ubisoft nie ponosi odpowiedzialności za zawartość żadnego pliku wchodzącego w skład modyfikacji czy innego pliku pobranego, nie gwarantuje bezpieczeństwa, jakości ani stabilności modyfikacji, jak również nie zapewnia wsparcia dla nich. Jeśli zdecydujesz się kontynuować, zrobisz to na własną odpowiedzialność. Ubisoft zastrzega sobie prawo do usunięcia modyfikacji w każdej chwili i z dowolnego powodu. Ubisoft zobowiązuje się do ochrony twoich danych osobowych. Ubisoft przetwarza twoje dane osobowe, aby zapewnić ci jak najlepsze wrażenia z korzystania modyfikacji, w tym w ramach ich wczytywania, pobierania i zgłaszania. Więcej informacji na temat tego, jak Ubisoft przetwarza twoje dane osobowe oraz jak możesz skorzystać z przysługujących ci praw, znajdziesz w naszej {mod_disclaimer_privacy}.',
    mod_disclaimer_privacy: 'Polityce prywatności',

    // Actions
    login: 'Zaloguj',
    logout: 'Wyloguj',
    search: 'Szukaj',
    clear_all: 'Wyczyść wszystko',
    download: 'Pobierz',
    subscribe: 'Subskrybuj',
    unsubscribe: 'Anuluj subskrypcję',
    resubscribe: 'Subskrybuj ponownie',
    activate: 'Aktywuj',
    deactivate: 'Dezaktywuj',
    like: 'Lubię',
    dislike: 'Nie lubię',
    report: 'Zgłoś',
    submit: 'Prześlij',
    cancel: 'Anuluj',

    // Filters
    all: 'Wszystko',
    available: 'dostępne(-ych)',
    filesize: 'Rozmiar pliku',
    downloads: 'Liczba pobrań',
    todays_downloads: 'Pobrania dziś',
    subscribers: 'Subskrybenci',
    rating: 'Ocena',
    sort_by: 'Sortuj według',
    creator: 'Twórca',

    // Login
    signup: 'Zaloguj się lub zarejestruj za pomocą adresu e-mail',
    email_placeholder: 'Adres e-mail',
    code_placeholder: '5-cyfrowy kod',
    have_code: 'Mam kod.',
    email_code: 'Wyślij kod',
    send_new_code: 'Wyślij nowy kod',
    check_email: 'Sprawdź pocztę i wprowadź otrzymany kod tutaj.',
    login_disclaimer:
      'Logujesz się w witrynie mod.io, której celem jest wspieranie zawartości gier tworzonej przez użytkowników.',
    terms_disclaimer:
      'Kontynuując, wyrażasz zgodę na obowiązywanie następujących dokumentów: {terms} i {privacy}. Musisz też mieć co najmniej 13 lat.',
    terms: 'Warunki korzystania',
    privacy: 'Polityka prywatności',
    code_error: 'Nie można poprosić o kod.',

    // Report
    report_heading: 'Zgłoś tego moda',
    report_intro:
      'Zgłoś treść naruszającą {terms} witryny lub prześlij {dmca} za pomocą poniższego formularza. Pamiętaj, by zawrzeć w nim wszystkie istotne informacje i linki.',
    dmca: 'zgłoszenie dotyczące naruszenia ustawy DMCA',
    reason: 'Powód zgłoszenia',
    urls: 'Obraźliwe adresy URL',
    urls_placeholder: 'Adresy URL (po jednym na wiersz)',
    company_or_name: 'Nazwa firmy lub Twoje imię i nazwisko',
    email: 'Adres e-mail',
    address: 'Adres',
    country: 'Kraj',
    country_placeholder: 'Wybierz kraj',
    details: 'Szczegóły naruszenia',
    details_placeholder:
      'Aby pomóc nam rozpatrzyć zgłoszenie, podaj jak najwięcej szczegółów i dowodów, w tym:\n\nadresy URL, daty i nazw kont, których dotyczy zgłoszenie;\nmiejsce, w którym można znaleźć oryginalną zawartość;\nnaruszenie, do którego doszło.',
    dmca_1:
      'Zespół {mod_io} szanuje prawa własności intelektualnej innych osób i prosimy wszystkich korzystających z naszych stron internetowych i usług o to samo. Każdy, kto uważa, że jego praca została powielona na jednej z naszych stron internetowych lub w jednej z usług w sposób stanowiący naruszenie praw autorskich, może powiadomić przedstawiciela ds. praw autorskich mod.io, korzystając z poniższego formularza.',
    dmca_2:
      'Zgłoszenie roszczenia dotyczącego naruszenia praw autorskich to poważna kwestia prawna. Zanim przejdziesz dalej, możesz rozważyć bezpośredni kontakt z daną osobą w celu złożenia skargi. To może być zwykłe nieporozumienie, które można rozwiązać bez angażowania się w faktyczny proces prawny.',
    dmca_3:
      'Pamiętaj, że zgodnie z sekcją 512(f) amerykańskiej ustawy Digital Millennium Copyright Act każda osoba, która świadomie wprowadza innych w błąd, twierdząc, że materiał lub działanie narusza prawa autorskie, może zostać pociągnięta do odpowiedzialności za wynikłe szkody. Pamiętaj też, że informacje zawarte w niniejszej nocie prawnej mogą być udostępniane stronom trzecim lub upubliczniane.',
    claim_check:
      'Zaznaczając poniższe pola i przesyłając niniejsze roszczenie, oświadczam POD KARĄ ZA SKŁADANIE FAŁSZYWYCH ZEZNIAŃ, co następuje:',
    copyright_owner: 'Jestem właścicielem(-ką) praw autorskich.',
    copyright_auth_owner:
      'Mam upoważnienie do działania w imieniu właściciela(-ki) praw autorskich w takiej sytuacji.',
    authorization: 'Upoważnienie',
    authorization_check:
      'W dobrej wierze wyrażam przekonanie, że wykorzystanie materiału wymienionego w tym roszczeniu nie zostało autoryzowane przez właściciela(-kę) praw autorskich, jego/jej przedstawiciela(-kę) lub nie jest zgodne z prawem.',
    acknowledgement: 'Potwierdzenie',
    acknowledgement_check:
      'Przyjmuję do wiadomości, że zgodnie z sekcją 512(f) amerykańskiej ustawy Digital Millennium Copyright Act każda osoba, która świadomie wprowadza innych w błąd, twierdząc, że materiał lub działanie narusza prawa autorskie, może zostać pociągnięta do odpowiedzialności za wynikłe szkody.',
    required: 'wymagane',
    signature: 'Podpis',
    signature_placeholder:
      'Wpisz imię i nazwisko w tym polu – będzie równoznaczne z Twoim podpisem cyfrowym.',
    report_success_title: 'Otrzymaliśmy Twoje zgłoszenie',
    report_success_text:
      'Jeśli Twoja wiadomość wymaga odpowiedzi, prosimy o cierpliwość, ponieważ staramy się odpowiadać na wszystkie zgłoszenia w ciągu 48 godzin od ich otrzymania.',
    report_error_title: 'Nie udało się przesłać zgłoszenia',

    // Table
    name: 'Nazwa',
    added: 'Dodano',
    updated: 'Zaktualizowano',
    file: 'Plik',
    version: 'Wersja',
    actions: 'Działania',
    locally_installed: 'Zainstalowane lokalnie',

    // Releases
    download_from_game_client: 'Pobierz moda {mod} z klienta gry',
    release_notes: 'Informacje o wydaniu',
    no_release_notes: 'Brak informacji o wydaniu',
    previous_releases: 'Poprzednie wydania',
    no_previous_releases: 'Nie ma poprzednich wydań.',
    no_files_published: 'Brak opublikowanych plików',

    // Ratings
    overwhelmingly_positive: 'Przytłaczająco pozytywne',
    overwhelmingly_negative: 'Przytłaczająco negatywne',
    very_positive: 'Bardzo pozytywne',
    very_negative: 'Bardzo negatywne',
    mostly_positive: 'Głównie pozytywne',
    mostly_negative: 'Głównie negatywne',
    negative: 'Negatywne',
    positive: 'Pozytywne',
    mixed: 'Mieszane',
    unrated: 'Brak ocen',

    // Sort
    sort_comments: 'Uwagi',
    sort_total_mods: 'Wszystkie mody',
    sort_trending: 'Popularne',
    sort_highest_rated: 'Najwyżej oceniany',
    sort_most_popular: 'Najbardziej popularny',
    sort_most_subscribers: 'Większość abonentów',
    sort_recently_added: 'Niedawno dodane',
    sort_last_updated: 'Ostatnio zaktualizowany',
    sort_alphabetical: 'Alfabetyczny',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: 'Nie działa',
    report_rude_content: 'Niegrzeczna treść',
    report_illegal_content: 'Nielegalna treść',
    report_stolen_content: 'Skradziona zawartość',
    report_false_information: 'Fałszywe informacje',
    report_other: 'Inne',

    // Errors
    login_fail: 'Nie udało się zalogować',
    login_fail_message:
      'Nie udało się zalogować. Poczekaj chwilę i spróbuj ponownie.',
    subscribe_fail: 'Nie udało się zasubskrybować',
    unsubscribe_fail: 'Nie udało się zrezygnować z subskrypcji',
  },
  ja: {
    language: '言語',

    // Nav
    browse: '閲覧',
    collection: 'マイコレクション',
    back_to_browse: '戻る',

    // Mods
    mod: 'Mod | Mods',
    content: 'コンテンツ',
    subscribed: '購読中',
    tags: 'タグ',
    summary: '概要',
    contributors: '貢献者',
    description: '説明',
    dependencies: '依存関係',
    of: '/',
    reset_filters: 'フィルターをリセット',
    no_results_found: '{ugc_name} は見つかりませんでした',
    no_subscriptions: 'どの {ugc_name} も購読していません',
    mod_disclaimer_heading: '法的免責事項',
    mod_disclaimer_text:
      'あなたがダウンロードしようとしているMODは、Ubisoftが限定して許可したAnno 1800エコシステム内でのみ使用できることに同意するものとします。あらゆるMODファイルおよびダウンロードのコンテンツに関し、Ubisoftは責任を有しません。また、MODの安全性、クオリティ、安定性における保証を行わないほか、サポートも提供しません。続ける場合、ご自身で責任を負っていただくこととなります。Ubisoftは、時期や理由を問わずMODを削除する権利を有します。Ubisoftはお客様の個人情報を守るために取り組んでいます。アップロード、ダウンロード、およびMODの報告を含む、MODの使用に関してお客様に最良の体験を提供させていただくために、Ubisoftはお客様の個人情報を処理します。Ubisoftによる個人情報の処理、およびお客様の権利の行使の詳細を確認したい方は、{mod_disclaimer_privacy}をご覧ください。',
    mod_disclaimer_privacy: 'プライバシーポリシー',

    // Actions
    login: 'ログイン',
    logout: 'ログアウト',
    search: '検索',
    clear_all: 'すべてクリア',
    download: 'ダウンロード',
    subscribe: '購読',
    unsubscribe: '購読解除',
    resubscribe: '再講読',
    activate: '有効化',
    deactivate: '無効化',
    like: 'いいね',
    dislike: '気に入らない',
    report: '報告',
    submit: '送信',
    cancel: 'キャンセル',

    // Filters
    all: 'すべて',
    available: '利用可能',
    filesize: 'ファイルサイズ',
    downloads: 'ダウンロード数',
    todays_downloads: '今日のダウンロード数',
    subscribers: '購読ユーザー',
    rating: '評価',
    sort_by: '並べ替え',
    creator: 'クリエイター',

    // Login
    signup: 'ログインまたはメールアドレスで登録',
    email_placeholder: 'メールアドレス',
    code_placeholder: '5 桁のコード',
    have_code: 'コードを持っています',
    email_code: 'コードをメール送信',
    send_new_code: '新しいコードを送信',
    check_email: 'メールに記載されたコードを確認し、ここに入力してください。',
    login_disclaimer:
      'ユーザー作成のゲーム内コンテンツをサポートするために使用される mod.io にログインしようとしています。',
    terms_disclaimer:
      '続行することにより、{terms}と{privacy}に同意するものとします。13 歳以上であることが必要です。',
    terms: '利用規約',
    privacy: 'プライバシーポリシー',
    code_error: 'コードをリクエストできません。',

    // Report
    report_heading: 'この mod を報告する',
    report_intro:
      '以下のフォームを使用してサイトの{terms}に違反するコンテンツを報告するか、{dmca}を送信してください。関連するすべての情報とリンクを必ず含めてください。',
    dmca: 'DMCA に関わる苦情',
    reason: '報告理由',
    urls: '違反のある URL',
    urls_placeholder: 'URL（1 行ずつ）',
    company_or_name: '会社またはあなたの名前',
    email: 'メール',
    address: 'アドレス',
    country: '国',
    country_placeholder: '国を選択してください',
    details: '侵害・違反の詳細',
    details_placeholder:
      '報告の処理に役立てるため、できる限り詳細な情報と証拠をご提供ください:\n\nURL、日付、関係のあるアカウント名\n元のコンテンツの場所\n発生した侵害・違反の内容',
    dmca_1:
      '{mod_io} は他者の知的財産権を尊重しており、当社のネットサイトとサービスをご利用のすべての方にも同様のことを求めています。当社のいずれかのネットサイトまたはサービスにおいて、著作権侵害にあたる何らかの方法で自分の作品が複製されていると思われる場合は、以下のフォームを使用して mod.io の著作権代理人に通知することができます。',
    dmca_2:
      '著作権侵害の申し立てを提出することは、重大な法的事項です。続行する前に、直接個人に連絡し、申し立ての内容に対処することを検討してください。適切な法的手続きをとらずとも対処できる単純な誤解である可能性もあります。',
    dmca_3:
      'デジタルミレニアム著作権法第 512 条(f) に基づき、作品または活動が侵害しているとして、故意に重大な虚偽を訴える者は、損害賠償責任を負う可能可能性があることに注意してください。また、この法的通知で提供される情報は第三者と共有されたり、公開されたりする可能性があることにも注意してください。',
    claim_check:
      '以下のチェックボックスをオンにしてこの申し立てを提出することにより、私は、偽証罪の罰則付きにより、以下のことを表明します。',
    copyright_owner: '私は著作権所有者です。',
    copyright_auth_owner:
      '私は、この状況において、著作権所有者に代わって行動する権限を有しています。',
    authorization: '許可',
    authorization_check:
      '私は、この申し立てに記載されている作品の使用が著作権所有者、その代理人、または法によって許可されていないと確信しています。',
    acknowledgement: '同意',
    acknowledgement_check:
      '私は、DMCA の第 512 条(f) に基づき、作品または活動が侵害しているとして故意に重大な虚偽を訴えた者は、損害賠償責任を負う可能性があることを認めます。',
    required: '必須',
    signature: '署名',
    signature_placeholder:
      'このボックスにデジタル署名として機能する氏名を入力してください。',
    report_success_title: 'あなたの報告を受領いたしました。',
    report_success_text:
      'あなたのメッセージに返信が必要な場合は、報告を受けてから 48 時間以内にすべての報告者に返信するように努めておりますので、しばらくお待ちください。',
    report_error_title: '報告を提出できませんでした',

    // Table
    name: '名前',
    added: '追加',
    updated: '更新',
    file: 'ファイル',
    version: 'バージョン',
    actions: 'アクション',
    locally_installed: 'ローカルにインストール済み',

    // Releases
    download_from_game_client: 'ゲームクライアントから {mod} をダウンロード',
    release_notes: 'リリースノート',
    no_release_notes: 'リリースノートはありません',
    previous_releases: '以前のリリース',
    no_previous_releases: '以前のリリースはありません',
    no_files_published: 'ファイルは公開されていません',

    // Ratings
    overwhelmingly_positive: '圧倒的にポジティブ',
    overwhelmingly_negative: '圧倒的にネガティブ',
    very_positive: '非常にポジティブ',
    very_negative: '非常にネガティブ',
    mostly_positive: 'ほとんどがポジティブ',
    mostly_negative: 'ほとんどがネガティブ',
    negative: 'ネガティブ',
    positive: 'ポジティブ',
    mixed: 'どちらでもない',
    unrated: '未評価',

    // Sort
    sort_comments: 'コメント',
    sort_total_mods: '総改造数',
    sort_trending: 'トレンド',
    sort_highest_rated: '最高評価',
    sort_most_popular: '最も人気のある',
    sort_most_subscribers: 'ほとんどの購読者',
    sort_recently_added: '最近追加された',
    sort_last_updated: '最終更新',
    sort_alphabetical: 'アルファベット順',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: '動作していません',
    report_rude_content: '失礼なコンテンツ',
    report_illegal_content: '違法なコンテンツ',
    report_stolen_content: '盗まれたコンテンツ',
    report_false_information: '虚偽の情報',
    report_other: 'その他',

    // Errors
    login_fail: 'ログインに失敗しました',
    login_fail_message:
      'ログインに失敗しました。ちょっと待って、もう一度やり直してください。',
    subscribe_fail: '購読に失敗しました',
    unsubscribe_fail: '登録解除に失敗しました',
  },
  ko: {
    language: '언어',

    // Nav
    browse: '탐색',
    collection: '내 컬렉션',
    back_to_browse: '돌아 가라',

    // Mods
    mod: 'Mod | Mods',
    content: '콘텐츠',
    subscribed: '구독',
    tags: '태그',
    summary: '요약',
    contributors: '기여자',
    description: '설명',
    dependencies: '종속성',
    of: '/',
    reset_filters: '필터 재설정',
    no_results_found: '{ugc_name}을(를) 찾을 수 없음',
    no_subscriptions: '{ugc_name}을(를) 구독하지 않았습니다.',
    mod_disclaimer_heading: '법적 면책 조항',
    mod_disclaimer_text:
      'Ubisoft가 특별 허용한 바에 따라, Anno 1800 게임 내에서만 이용하는 것으로 동의한 모드를 다운로드합니다. Ubisoft는 어떤 모드 파일 또는 다운로드 콘텐츠에 대해서도 책임을 지지 않으며, 모드의 안전성, 품질 또는 안정성을 보장하지 않을 뿐만 아니라 모드에 대한 지원을 제공하지 않습니다. 계속하기를 희망할 경우 모든 위험 부담은 사용자에게 있습니다. Ubisoft는 어떠한 이유로든 아무 때나 모드를 제거할 권한이 있습니다. Ubisoft는 개인정보 보호에 최선을 다하고 있습니다. Ubisoft는 모드의 업로드, 다운로드, 신고를 비롯하여 모드 사용 전반에 걸쳐 최상의 경험을 제공하기 위해 개인정보를 활용합니다. Ubisoft가 개인정보를 활용하는 방식 및 자신의 권리를 행사하는 법에 대해 더 자세히 알고 싶으시다면 {mod_disclaimer_privacy}.',
    mod_disclaimer_privacy: '개인정보보호방침을 참조해 주세요',

    // Actions
    login: '로그인',
    logout: '로그 아웃',
    search: '검색',
    clear_all: '모두 지우기',
    download: '다운로드',
    subscribe: '구독',
    unsubscribe: '구독 취소',
    resubscribe: '재구독',
    activate: '활성화',
    deactivate: '비활성화',
    like: '좋아요',
    dislike: '싫어요',
    report: '신고',
    submit: '제출',
    cancel: '취소',

    // Filters
    all: '모두',
    available: '사용 가능',
    filesize: '파일 크기',
    downloads: '다운로드',
    todays_downloads: '오늘의 다운로드',
    subscribers: '가입자',
    rating: '평가',
    sort_by: '정렬 기준',
    creator: '생성자',

    // Login
    signup: '이메일로 로그인 또는 가입',
    email_placeholder: '이메일 주소',
    code_placeholder: '5자리 코드',
    have_code: '코드가 있습니다',
    email_code: '이메일 코드',
    send_new_code: '새 코드 보내기',
    check_email: '이메일에서 코드를 확인하고 여기에 입력하세요.',
    login_disclaimer:
      '게임 내 사용자 생성 콘텐츠를 지원하는 데 사용되는 mod.io에 로그인하고 있습니다.',
    terms_disclaimer:
      '계속함으로써, 귀하는 {terms}과 {privacy}에 동의하게 되고 13세 이상이어야 합니다.',
    terms: '이용약관',
    privacy: '개인정보 보호 정책',
    code_error: '코드를 요청할 수 없습니다.',

    // Report
    report_heading: '이 Mod 신고',
    report_intro:
      '사이트의 {terms} 위반하는 콘텐츠를 신고하거나 아래 양식을 사용하여 {dmca}를 제출하십시오. 모든 관련 정보와 링크를 포함했는지 확인하십시오.',
    dmca: 'DMCA 불만 제기',
    reason: '신고 이유',
    urls: '잘못된 URL',
    urls_placeholder: 'URL(한 줄에 1개)',
    company_or_name: '회사 또는 귀하의 이름',
    email: '이메일',
    address: '주소',
    country: '국가',
    country_placeholder: '국가를 선택하세요',
    details: '위반 사항 세부 정보',
    details_placeholder:
      '신고를 처리하는 데 도움이 되도록 URL, 날짜, 관련된 계정 이름\n\n원본 콘텐츠를 찾을 수 있는 위치\n발생한 위반 사항을 포함하여\n가능한 한 많은 세부 정보와 증거를 제공하십시오.',
    dmca_1:
      '{mod_io}는 타인의 지적 재산권을 존중하며 당사 인터넷 사이트 및 서비스를 사용하는 모든 사람이 동일하게 행동할 것을 요청합니다. 자신의 작품이 저작권을 침해하는 방식으로 당사의 인터넷 사이트 또는 서비스 중 하나에 복제되었다고 생각하는 사람은 아래 양식을 사용하여 mod.io의 저작권 대리인에게 알릴 수 있습니다.',
    dmca_2:
      '저작권 침해 주장을 제출하는 것은 심각한 법적 문제입니다. 진행하기 전에 개인에게 직접 연락하여 불만 사항을 처리하는 것을 고려할 수 있습니다. 단순한 오해일 수 있으며 적절한 법적 절차를 거치지 않고 해결할 수 있습니다.',
    dmca_3:
      '디지털 밀레니엄 저작권법(Digital Millennium Copyright Act) 512(f)절에 따라 자료나 활동이 저작권을 침해한다고 고의적으로 실질적으로 허위 진술하는 사람은 손해 배상 책임을 질 수 있습니다. 또한 이 법적 고지에 제공된 정보는 제3자와 공유되거나 공개될 수 있습니다.',
    claim_check:
      '다음 확인란을 선택하고 이러한 불만 사항을 제출함으로써 본인은 위증 시 처벌을 받는다는 조건 하에 다음과 같이 진술합니다.',
    copyright_owner: '본인은 저작권 소유자입니다',
    copyright_auth_owner:
      '본인은 이 상황에서 저작권 소유자를 대신하여 행동할 권한이 있습니다.',
    authorization: '권한 부여',
    authorization_check:
      '본인은 이 불만 사항에 언급된 자료의 사용이 저작권 소유자, 그 대리인 또는 법률에 의해 승인되지 않았음을 선의로 믿습니다.',
    acknowledgement: '인정',
    acknowledgement_check:
      '본인은 DMCA 512(f)절에 따라 자료나 활동이 저작권을 침해한다고 고의적으로 실질적으로 허위 진술하는 사람은 손해 배상 책임을 질 수 있음을 인정합니다.',
    required: '필수',
    signature: '서명',
    signature_placeholder:
      '디지털 서명 역할을 하는 성명을 이 확인란에 입력하십시오.',
    report_success_title: '귀하의 신고가 접수되었습니다',
    report_success_text:
      '귀하의 메시지에 대한 답변이 필요한 경우 모든 보고서를 받은 후 48시간 이내에 답변하기 위해 최선을 다하고 있으니 조금만 기다려 주십시오.',
    report_error_title: '보고서를 제출하지 못했습니다',

    // Table
    name: '이름',
    added: '추가됨',
    updated: '업데이트됨',
    file: '파일',
    version: '버전',
    actions: '행동',
    locally_installed: '로컬로 설치됨',

    // Releases
    download_from_game_client: '게임 클라이언트에서 {mod} 다운로드',
    release_notes: '릴리즈 노트',
    no_release_notes: '릴리스 노트가 제공되지 않음',
    previous_releases: '이전 릴리스',
    no_previous_releases: '이전 릴리스가 없습니다',
    no_files_published: '게시된 파일 없음',

    // Ratings
    overwhelmingly_positive: '압도적으로 긍정적',
    overwhelmingly_negative: '압도적으로 부정적',
    very_positive: '매우 긍정적',
    very_negative: '매우 부정적',
    mostly_positive: '대부분 긍정적',
    mostly_negative: '대부분 부정적',
    negative: '부정적',
    positive: '긍정적',
    mixed: '혼합',
    unrated: '등급 평가 없음',

    // Sort
    sort_comments: '코멘트',
    sort_total_mods: '총 모드',
    sort_trending: '트렌드',
    sort_highest_rated: '최고 평점',
    sort_most_popular: '가장 인기 많은',
    sort_most_subscribers: '대부분의 가입자',
    sort_recently_added: '최근에 추가',
    sort_last_updated: '마지막 업데이트',
    sort_alphabetical: '알파벳순',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: '작동하지 않음',
    report_rude_content: '무례한 콘텐츠',
    report_illegal_content: '불법 콘텐츠',
    report_stolen_content: '훔친 콘텐츠',
    report_false_information: '거짓 정보',
    report_other: '기타',

    // Errors
    login_fail: '로그인 실패',
    login_fail_message: '로그인 실패. 잠시만 기다려 다시 시도하십시오.',
    subscribe_fail: '구독하지 못했습니다',
    unsubscribe_fail: '구독 취소 실패',
  },
  ru: {
    language: 'Язык',

    // Nav
    browse: 'Просмотр',
    collection: 'Моя коллекция',
    back_to_browse: 'Возвращаться',

    // Mods
    mod: 'Mod | Mods',
    content: 'Содержимое',
    subscribed: 'В подписках',
    tags: 'Теги',
    summary: 'Сведения',
    contributors: 'Участники',
    description: 'Описание',
    dependencies: 'Зависимости',
    of: 'из',
    reset_filters: 'Сбросить фильтры',
    no_results_found: '{ugc_name} не найдено',
    no_subscriptions: 'Вы не подписаны ни на один {ugc_name}',
    mod_disclaimer_heading: 'Заявление об отказе от ответственности',
    mod_disclaimer_text:
      'Компанией Ubisoft предусмотрено использование загружаемой модификации только в рамках экосистемы Anno 1800. Компания Ubisoft не несет ответственности за содержание файлов модификаций или загрузок и не может гарантировать безопасность, качество и стабильность работы модификаций, а также не осуществляет связанную с ними поддержку. Продолжая загрузку, вы берете на себя всю ответственность за последствия. Компания Ubisoft оставляет за собой право в любое время и по любой причине удалять модификации. Неизменной политикой компании Ubisoft является защита ваших персональных данных. Для наиболее комфортного использования модификаций (включая загрузку, скачивание и отправку жалоб) компания Ubisoft обрабатывает ваши персональные данные. Чтобы узнать подробности об обработке ваших персональных данных или воспользоваться своими правами в данной области, ознакомьтесь с {mod_disclaimer_privacy}.',
    mod_disclaimer_privacy: 'Положением об использовании персональных данных',

    // Actions
    login: 'Вход',
    logout: 'Выйти',
    search: 'Поиск',
    clear_all: 'Очистить все',
    download: 'Загрузить',
    subscribe: 'Подписаться',
    unsubscribe: 'Отписаться',
    resubscribe: 'Подписаться снова',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    like: 'Нравится',
    dislike: 'Не нравится',
    report: 'Пожаловаться',
    submit: 'Отправить',
    cancel: 'Отмена',

    // Filters
    all: 'Все',
    available: 'для загрузки',
    filesize: 'Размер файла',
    downloads: 'Загрузок',
    todays_downloads: 'Загрузок сегодня',
    subscribers: 'Подписчиков',
    rating: 'Рейтинг',
    sort_by: 'Сортировать по',
    creator: 'Создатель',

    // Login
    signup: 'Войдите или зарегистрируйтесь с помощью эл. почты',
    email_placeholder: 'Адрес эл. почты',
    code_placeholder: '5-значный код',
    have_code: 'У меня есть код',
    email_code: 'Отправить код письмом',
    send_new_code: 'Отправить новый код',
    check_email:
      'Проверьте электронную почту на наличие письма с кодом и введите код здесь.',
    login_disclaimer:
      'Вы выполняете вход на mod.io — сервис для поддержки внутриигрового контента, создаваемого пользователями.',
    terms_disclaimer:
      'Продолжая, вы принимаете положения документов {terms} и {privacy}. Вы также не должны быть моложе 13 лет.',
    terms: 'Условия использования',
    privacy: 'Политика конфиденциальности',
    code_error: 'Не удается запросить код.',

    // Report
    report_heading: 'Пожаловаться на этот мод',
    report_intro:
      'Пожалуйтесь на содержимое, нарушающее {terms} сайта. {dmca} (форма ниже) нужна для случаев предположительного нарушения авторских прав. При заполнении не забудьте указать всю необходимую информацию и предоставить ссылки.',
    dmca: 'Жалоба на нарушение авторского права',
    reason: 'Основания для жалобы',
    urls: 'URL-адреса материалов, нарушающих авторское право',
    urls_placeholder: 'URL-адреса (по 1 в строке)',
    company_or_name: 'Название компании или ваше имя',
    email: 'Адрес эл. почты',
    address: 'Адрес',
    country: 'Страна',
    country_placeholder: 'Выберите страну',
    details: 'Подробности о нарушении авторского права',
    details_placeholder:
      'Вы поможете нам обработать вашу жалобу, если предоставите как можно больше информации, а также доказательства нарушения, включая:\n\nURL-адреса, даты, имена вовлеченных учетных записей;\nуказание, где можно ознакомиться с оригинальной работой;\nописание произошедшего нарушения.',
    dmca_1:
      '{mod_io} уважает владельцев прав на интеллектуальную собственность и призывает к этому всех, кто пользуется нашими сайтами и службами. Если вы считаете, что ваша работа была использована на наших сайтах или в службах с нарушением авторского права, вы можете уведомить об этом агента по авторским правам в mod.io, используя форму ниже.',
    dmca_2:
      'Подача иска о нарушении авторского права — серьезный правовой вопрос. Перед тем, как продолжить, вы могли бы связаться с нарушителем и попытаться разрешить проблему напрямую с ним. Возможно, возникло простое недоразумение, которое можно урегулировать без привлечения надлежащих правовых процессов.',
    dmca_3:
      'Помните: в соответствии с разделом 512(f) Закона об авторском праве в цифровую эпоху, лицо, сообщившее заведомо ложные сведения о нарушении авторского права, может быть привлечено к ответственности за причиненный ущерб. Также обратите внимание, что информация, указанная в этом юридическом уведомлении, может быть передана третьим сторонам или стать общедоступной.',
    claim_check:
      'Проставляя отметки и отправляя эту форму, я заявляю, НЕСЯ ОТВЕТСТВЕННОСТЬ ЗА ЛЖЕСВИДЕТЕЛЬСТВО, что:',
    copyright_owner: 'я являюсь правообладателем;',
    copyright_auth_owner:
      'я имею полномочия действовать от имени владельца авторских прав в этой ситуации.',
    authorization: 'Наличие разрешения',
    authorization_check:
      'Я добросовестно полагаю, что использование материалов, указанных в этом заявлении, не разрешено владельцем авторских прав, его представителем или законом.',
    acknowledgement: 'Подтверждение',
    acknowledgement_check:
      'Я понимаю, что в соответствии с разделом 512(f) Закона об авторском праве в цифровую эпоху (DMCA), лицо, сообщившее заведомо ложные сведения о нарушении авторского права, несет ответственность за причиненный ущерб.',
    required: 'обязательное поле',
    signature: 'Цифровая подпись',
    signature_placeholder:
      'Введите в это поле свое полное имя, которое будет использоваться в качестве цифровой подписи.',
    report_success_title: 'Ваша жалоба была получена',
    report_success_text:
      'Если ваше сообщение требует ответа, проявите немного терпения. Мы стараемся отвечать на все жалобы в течение 48 часов с момента их получения.',
    report_error_title: 'Не удалось отправить жалобу',

    // Table
    name: 'Название',
    added: 'Добавлен',
    updated: 'Обновлен',
    file: 'Файл',
    version: 'Версия',
    actions: 'Действия',
    locally_installed: 'На устройстве',

    // Releases
    download_from_game_client: 'Загрузить мод {mod} из клиента игры',
    release_notes: 'Сведения о версии',
    no_release_notes: 'Сведения о версии не указаны',
    previous_releases: 'Предыдущие версии',
    no_previous_releases: 'Предыдущие версии отсутствуют',
    no_files_published: 'Нет опубликованных файлов',

    // Ratings
    overwhelmingly_positive: 'Крайне положительный',
    overwhelmingly_negative: 'Крайне отрицательный',
    very_positive: 'Очень положительный',
    very_negative: 'Очень отрицательный',
    mostly_positive: 'В основном положительный',
    mostly_negative: 'В основном отрицательный',
    negative: 'Отрицательный',
    positive: 'Положительный',
    mixed: 'Смешанный',
    unrated: 'Без рейтинга',

    // Sort
    sort_comments: 'Комментарии',
    sort_total_mods: 'Всего модов',
    sort_trending: 'В тренде',
    sort_highest_rated: 'Наивысший рейтинг',
    sort_most_popular: 'Самый популярный',
    sort_most_subscribers: 'Большинство подписчиков',
    sort_recently_added: 'Недавно добавленный',
    sort_last_updated: 'Последнее обновление',
    sort_alphabetical: 'Алфавитный',

    // Report Reason
    report_dmca: 'Закон о защите авторских прав в цифровую эпоху',
    report_not_working: 'Не работает',
    report_rude_content: 'Грубый контент',
    report_illegal_content: 'Незаконный контент',
    report_stolen_content: 'Украденный контент',
    report_false_information: 'Ложная информация',
    report_other: 'Другое',

    // Errors
    login_fail: 'Не удалось войти',
    login_fail_message:
      'Не удалось войти. Пожалуйста, подождите минутку и попробуйте еще раз.',
    subscribe_fail: 'Не удалось подписаться',
    unsubscribe_fail: 'Не удалось отписаться',
  },
  'zh-CN': {
    language: '语言',

    // Nav
    browse: '浏览',
    collection: '我的收藏',
    back_to_browse: '回去',

    // Mods
    mod: 'Mod | Mods',
    content: '内容',
    subscribed: '已订阅',
    tags: '标签',
    summary: '概要',
    contributors: '贡献者',
    description: '描述',
    dependencies: '依赖项',
    of: '/',
    reset_filters: '重置过滤器',
    no_results_found: '未找到 {ugc_name}',
    no_subscriptions: '你还没有订阅任何 {ugc_name}',
    mod_disclaimer_heading: '法律免责声明',
    mod_disclaimer_text:
      '你即将下载模块，亦即代表你同意此模块仅用于 Ubisoft 许可的特定《纪元 1800》游戏环境内。Ubisoft 对模块文件或下载内容概不负责，且无法保证模块的安全性、质量或稳定性，也不会对模块提供支持。若选择继续，风险自负。Ubisoft 保有随时将模块移除的权力。Ubisoft 致力于保护你的个人数据。为提供模块使用的最佳体验，包含上传、下载和检举模块，Ubisoft 会处理你的个人数据。欲知 Ubisoft 如何处理你的个人数据以及如何行使个人权利，{mod_disclaimer_privacy}。',
    mod_disclaimer_privacy: '请查看我们的隐私权政策',

    // Actions
    login: '登录',
    logout: '退出登录',
    search: '搜索',
    clear_all: '全部清除',
    download: '下载',
    subscribe: '订阅',
    unsubscribe: '取消订阅',
    resubscribe: '重新订阅',
    activate: '激活',
    deactivate: '停用',
    like: '顶',
    dislike: '踩',
    report: '举报',
    submit: '提交',
    cancel: '取消',

    // Filters
    all: '全部',
    available: '可用',
    filesize: '文件大小',
    downloads: '下载量',
    todays_downloads: '今日下载量',
    subscribers: '订阅者',
    rating: '评分',
    sort_by: '排序方式',
    creator: '创建者',

    // Login
    signup: '使用电子邮件登录或注册',
    email_placeholder: '电子邮件地址',
    code_placeholder: '5 位代码',
    have_code: '我有代码',
    email_code: '通过电子邮件发送代码',
    send_new_code: '发送新代码',
    check_email: '请查看你的电子邮件获取代码并在这里输入。',
    login_disclaimer:
      '你正在登录到 mod.io，mod.io 用于支持用户生成的游戏中内容。',
    terms_disclaimer:
      '继续，即表示你同意{terms}和{privacy}，并且必须年满 13 岁。',
    terms: '使用条款',
    privacy: '隐私政策',
    code_error: '无法请求代码。',

    // Report
    report_heading: '举报此 mod',
    report_intro:
      '举报违反网站{terms}的内容或使用以下表单提交 {dmca}。确保包含所有相关信息和链接。',
    dmca: 'DMCA 投诉',
    reason: '举报原因',
    urls: '违规网址',
    urls_placeholder: '网址（每行 1 个）',
    company_or_name: '公司或你的名字',
    email: '电子邮件',
    address: '地址',
    country: '国家/地区',
    country_placeholder: '选择国家/地区',
    details: '侵权详情',
    details_placeholder:
      '为了让我们更好地处理举报，请提供尽可能多的详细信息和证据，包括\n\n涉及的网址、日期、帐户名\n可以找到原始内容的位置\n发生的侵权行为',
    dmca_1:
      '{mod_io} 尊重他人的知识产权，我们要求每个使用我们网站和服务的人都这样做。如果你认为你的作品在我们的网站或服务中被以侵权版权的方式重现，你可以使用以下表单通知 mod.io 的版权代理。',
    dmca_2:
      '提交版权侵犯申诉是一项严肃的法律事务。在继续之前，你可以考虑直接联系当事人以解决投诉。这可能只是一个误会，可以在不涉及适当法律程序的情况下解决。',
    dmca_3:
      '请注意，根据《数字千年版权法案》第 512(f) 条，任何人如果故意歪曲材料或活动侵权，则可能需要承担损害赔偿责任。另请注意，本法律声明中提供的信息可能会与第三方共享或公开。',
    claim_check: '勾选以下方框并提交此申诉，如有伪证则将受罚，我声明',
    copyright_owner: '我是版权所有者',
    copyright_auth_owner: '在这种情况下，我被授权代表版权所有者行事。',
    authorization: '授权',
    authorization_check:
      '我真诚相信，本声明中所述材料的使用未经版权所有者、其代理人或法律授权。',
    acknowledgement: '承认书',
    acknowledgement_check:
      '我承认，根据 DMCA 第 512(f) 条，任何人如果故意歪曲材料或活动侵权，则可能需要承担损害赔偿责任。',
    required: '必填',
    signature: '签名',
    signature_placeholder: '在此框中输入你的全名，作为你的数字签名。',
    report_success_title: '你的举报已送达',
    report_success_text:
      '如果你的消息需要回复，请耐心等待，我们会尽力在收到举报后的 48 小时内回复。',
    report_error_title: '提交举报失败',

    // Table
    name: '名称',
    added: '存在时长',
    updated: '更新日期',
    file: '文件',
    version: '版本',
    actions: '操作',
    locally_installed: '本地安装',

    // Releases
    download_from_game_client: '从游戏客户端下载 {mod}',
    release_notes: '版本说明',
    no_release_notes: '未提供版本说明',
    previous_releases: '之前的版本',
    no_previous_releases: '没有之前的版本',
    no_files_published: '未发布文件',

    // Ratings
    overwhelmingly_positive: '好评如潮',
    overwhelmingly_negative: '差评如潮',
    very_positive: '特别好评',
    very_negative: '特别差评',
    mostly_positive: '多半好评',
    mostly_negative: '多半差评',
    negative: '差评',
    positive: '好评',
    mixed: '褒贬不一',
    unrated: '未评分',

    // Sort
    sort_comments: '评论',
    sort_total_mods: '模组总数',
    sort_trending: '热门',
    sort_highest_rated: '收视率最高',
    sort_most_popular: '最受欢迎',
    sort_most_subscribers: '大多数订阅者',
    sort_recently_added: '最近添加的',
    sort_last_updated: '最近更新时间',
    sort_alphabetical: '按字母顺序',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: '不工作',
    report_rude_content: '粗鲁的内容',
    report_illegal_content: '非法内容',
    report_stolen_content: '被盗内容',
    report_false_information: '虚假信息',
    report_other: '其他',

    // Errors
    login_fail: '登录失败',
    login_fail_message: '登录失败。请等待一分钟，然后重试。',
    subscribe_fail: '未能订阅',
    unsubscribe_fail: '未能取消订阅',
  },
  'zh-TW': {
    language: '語言',

    // Nav
    browse: '瀏覽',
    collection: '我的收藏',
    back_to_browse: '回去',

    // Mods
    mod: 'Mod | Mods',
    content: '內容',
    subscribed: '已訂閱',
    tags: '標籤',
    summary: '摘要',
    contributors: '貢獻者',
    description: '說明',
    dependencies: '依賴項',
    of: '/',
    reset_filters: '重置過濾器',
    no_results_found: '未找到 {ugc_name}',
    no_subscriptions: '你還沒有訂閱任何 {ugc_name}',
    mod_disclaimer_heading: '法律免責聲明',
    mod_disclaimer_text:
      '你即將下載模組，亦即代表你同意此模組僅用於 Ubisoft 許可的特定《美麗新世界 1800》遊戲環境內。Ubisoft 對模組檔案或下載內容概不負責，且無法保證模組的安全性、品質或穩定性，也不會對模組提供支援。若選擇繼續，風險自負。Ubisoft 保有隨時將模組移除的權力。Ubisoft 致力於保護你的個人資料。為提供模組使用的最佳體驗，包含上傳、下載和檢舉模組，Ubisoft 會處理你的個人資料。欲知 Ubisoft 如何處理你的個人資料以及如何行使個人權利，請查看我們的{mod_disclaimer_privacy}。',
    mod_disclaimer_privacy: '隱私權政策',

    // Actions
    login: '登入',
    logout: '登出',
    search: '搜尋',
    clear_all: '全部清除',
    download: '下載',
    subscribe: '訂閱',
    unsubscribe: '取消訂閱',
    resubscribe: '重新訂閱',
    activate: '啟用',
    deactivate: '停用',
    like: '喜歡',
    dislike: '不喜歡',
    report: '檢舉',
    submit: '提交',
    cancel: '取消',

    // Filters
    all: '全部',
    available: '可用',
    filesize: '檔案大小',
    downloads: '下載次數',
    todays_downloads: '本日下載次數',
    subscribers: '訂閱者',
    rating: '評分',
    sort_by: '排序方式',
    creator: '建立者',

    // Login
    signup: '以電子郵件登入或註冊',
    email_placeholder: '電子郵件地址',
    code_placeholder: '5 位代碼',
    have_code: '我有代碼',
    email_code: '透過電子郵件傳送代碼',
    send_new_code: '傳送新代碼',
    check_email: '請檢查你電子郵件中的代碼，並在此處輸入。',
    login_disclaimer:
      '你正在登入 mod.io，該網站旨在支援使用者於遊戲中所產生的內容。',
    terms_disclaimer:
      '你必須年滿 13 歲才能繼續；繼續即表示你同意 {terms} 和 {privacy}。',
    terms: '使用條款',
    privacy: '隱私權政策',
    code_error: '無法要求代碼。',

    // Report
    report_heading: '檢舉此 mod',
    report_intro:
      '請使用下方表格來檢舉違反網站 {terms} 的內容或提交 {dmca}。請務必包含所有相關的資訊和連結。',
    dmca: 'DMCA 投訴',
    reason: '檢舉的原因',
    urls: '違規網址',
    urls_placeholder: '網址 (每行 1 個)',
    company_or_name: '公司或你的姓名',
    email: '電子郵件',
    address: '地址',
    country: '國家/地區',
    country_placeholder: '選取國家/地區',
    details: '侵權或違規的細節',
    details_placeholder:
      '為了協助我們處理你的報告，請盡量提供細節和證據，包括\n侵權或違規的網址、日期、帳戶名稱\n在哪裡可以找到原始內容\n已發生的侵權行為',
    dmca_1:
      '{mod_io} 尊重他人的智慧財產權，也要求每個使用我們網站和服務的人也這麼做。如果有人認為自己的作品在我們的網站或服務中遭到複製，從而構成著作權侵犯，請使用下方的表格來通知 mod.io 的著作權代理人。',
    dmca_2:
      '提出侵犯著作權的申訴是一件嚴肅的法律事務。在你繼續之前，或許可以考慮直接聯繫涉及此事的個人來解決問題。這可能只是一個簡單的誤會，或許不用牽涉到正式的法律程序也能處理。',
    dmca_3:
      '請注意，根據數位千禧年著作權法案第 512(f) 款，如有任何人明知不實仍陳述某些材料或活動侵犯著作權，均可能承擔損害賠償責任。另外，請注意，這份法律通知中提供的資訊可能會與第三方分享或公開。',
    claim_check: '透過勾選以下方塊並提交本申訴，我依法據實陳述',
    copyright_owner: '我是著作權所有人',
    copyright_auth_owner: '在此種情況下，我有權代表著作權所有人行事。',
    authorization: '授權',
    authorization_check:
      '我有正當理由相信，本申訴所指材料之使用，未經著作權所有人、其代理人或法律授權。',
    acknowledgement: '知悉書',
    acknowledgement_check:
      '我知悉，根據數位千禧年著作權法案第 512(f) 款，任何明知不實仍陳述某些材料或活動侵犯著作權者，均可能承擔損害賠償責任。',
    required: '必填',
    signature: '簽名',
    signature_placeholder: '在此框中鍵入你的全名，作為你的數位簽名。',
    report_success_title: '你的檢舉已送達',
    report_success_text:
      '如果你的訊息需要回覆，請耐心等待。我們會努力在收到檢舉後 48 小時內回覆。',
    report_error_title: '提交檢舉失敗',

    // Table
    name: '名稱',
    added: '建立時間',
    updated: '更新時間',
    file: '檔案',
    version: '版本',
    actions: '動作',
    locally_installed: '已安裝於本機',

    // Releases
    download_from_game_client: '從遊戲用戶端下載 {mod}',
    release_notes: '版本說明',
    no_release_notes: '未提供版本說明',
    previous_releases: '之前的版本',
    no_previous_releases: '沒有之前的版本',
    no_files_published: '未發佈檔案',

    // Ratings
    overwhelmingly_positive: '壓倒性好評',
    overwhelmingly_negative: '壓倒性負評',
    very_positive: '非常多好評',
    very_negative: '非常多負評',
    mostly_positive: '多數好評',
    mostly_negative: '多數負評',
    negative: '負評',
    positive: '好評',
    mixed: '褒貶不一',
    unrated: '未評分',

    // Sort
    sort_comments: '評論',
    sort_total_mods: '模組總數',
    sort_trending: '熱門',
    sort_highest_rated: '收視率最高',
    sort_most_popular: '最受歡迎',
    sort_most_subscribers: '大多數訂閱者',
    sort_recently_added: '最近添加的',
    sort_last_updated: '最近更新時間',
    sort_alphabetical: '按字母順序',

    // Report Reason
    report_dmca: 'DMCA',
    report_not_working: '不工作',
    report_rude_content: '粗魯的內容',
    report_illegal_content: '非法內容',
    report_stolen_content: '被盜內容',
    report_false_information: '虛假信息',
    report_other: '其他',

    // Errors
    login_fail: '登錄失敗',
    login_fail_message: '登錄失敗。請等待一分鐘，然後重試。',
    subscribe_fail: '未能訂閱',
    unsubscribe_fail: '未能取消訂閱',
  },
}
